import React, { useState, useEffect } from "react";
import { useTheme } from "../context/ThemeContext";
import { SunIcon, MoonIcon } from "@heroicons/react/outline";
import { Link } from "react-scroll";
import { MenuIcon, XIcon } from "@heroicons/react/solid";

const Navbar = () => {
  const { toggleDarkMode, isDarkMode } = useTheme();
  const [scrollY, setScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onButtonClick = () => {
    fetch("myCV.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "myCV.pdf";
        alink.click();
      });
    });
  };

  return (
    <section
      id="navbar"
      className={`${
        scrollY > 0
          ? "fixed bg-opacity-70 backdrop-filter backdrop-blur-md"
          : ""
      } dark:bg-gray-900 transition-all duration-300 w-full top-0 z-10`}
    >
      <div className="text-2xl container flex items-center justify-between mx-auto px-8">
        {/* Logo */}
        <a href="https://givarihertz.com" className="flex items-center">
          <h1 className="font-bold uppercase dark:text-white">
            Givari{" "}
            <span className="text-orange-500 font-bold dark:">Hertz</span>
          </h1>
        </a>

        {/* Hamburger Menu (Mobile Only) */}
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none text-gray-700 dark:text-white"
          >
            {isMenuOpen ? (
              <XIcon className="h-8 w-8" />
            ) : (
              <MenuIcon className="h-8 w-8" />
            )}
          </button>
        </div>

        

        {/* Navbar Links (Desktop) */}
        <div className="hidden md:flex items-center space-x-4">
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="text-gray-700 dark:text-white hover:font-bold cursor-pointer"
          >
            About
          </Link>
          <Link
            to="porto"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="text-gray-700 dark:text-white hover:font-bold cursor-pointer"
          >
            Projects
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="text-gray-700 dark:text-white hover:font-bold cursor-pointer"
          >
            Contact
          </Link>
        </div>

        {/* Navbar Buttons (Desktop) */}
        <div className="hidden md:flex items-center">
          <button
            onClick={onButtonClick}
            className={`text-2xl hover:bg-black hover:text-white focus:outline-none font-medium
              rounded-lg px-5 py-2.5 text-center mr-3 md:mr-0 dark:text-white dark:hover:text-orange-500 ${
                scrollY > 0 ? "dark:hover:bg-opacity-70" : ""
              }`}
          >
            myCV
          </button>

          {/* Toggle Dark Mode */}
          <button
            onClick={toggleDarkMode}
            className={`text-2xl hover:text-orange-500 focus:outline-none font-medium
              rounded-lg px-5 py-2.5 text-center dark:text-white dark:hover:text-orange-500 transition-all duration-300`}
          >
            {isDarkMode ? (
              <SunIcon className="h-6 w-6" />
            ) : (
              <MoonIcon className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Hamburger Menu Dropdown (Mobile Only) */}
        {isMenuOpen && (
          <div className="absolute top-16 right-0 w-64 bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4 md:hidden">
            <ul className="flex flex-col gap-2">
              <li>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="block text-gray-700 dark:text-white hover:font-bold"
                  onClick={() => setIsMenuOpen(false)}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="porto"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="block text-gray-700 dark:text-white hover:font-bold"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="block text-gray-700 dark:text-white hover:font-bold"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Contact
                </Link>
              </li>
              <li>
                <button
                  onClick={onButtonClick}
                  className="block w-full text-left text-gray-700 dark:text-white hover:font-bold"
                >
                  myCV
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Navbar;
